:root {
    --toastify-color-light: #fff; /* Light background color for the toast */
    --toastify-color-dark: #121212; /* Dark background color for the toast */
  
    --toastify-color-success: #020055;
    --toastify-font-family: 'DG-Heaven'
   
    ;

}
    .Toastify__toast-container--top-center {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

      .Toastify__progress-bar--success {
        background: linear-gradient(to right, #020055, #00D2DD, #F3D600);
      }

      .Toastify__toast {
        padding: 30px; /* Adjust the padding value as needed */
      }
      
      
.Toastify__toast-body {
  color: #020055; /* Change the font color */
}
      .Toastify__close-button {
       position: absolute;
          top: 10px; /* Move the close button to the top */
          right: 10px; /* Align the close button to the top right corner */
        
      }
    
  
  